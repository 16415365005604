<template>
    <div class="m-2">
        <bread-crumbs :items="items" />

        <v-row style="direction:ltr">
             <v-col
            cols="12"
            md="5"
            sm="12">
            <b-input-group class="mt-3">
                
                <b-input-group-append style="border-left:1px solid #CCC">
                    <b-button variant="light" style="background: darkblue !important;font-size:14px;color:#FFF;padding:7px 22px;border-radius:5px" @click="getReport()">{{ lang.search }}</b-button>
                </b-input-group-append>
            </b-input-group>
            </v-col>
            <v-col cols="12" md="7" sm="12" class="text-right">
                <b-button variant="light" style="width:145px;margin-right:9px;display:none" class="mytab mytab1 mt-3" @click="exportE('e')">{{ lang.excel_export }}</b-button>
            </v-col>
        </v-row>

        <template>
        <v-data-table
            :headers="headers"
            :items="cardrows"
            :items-per-page="10"
            class="elevation-1"
        ></v-data-table>
        </template>

        
        <b-sidebar id="cards-reports-search" style="direction:ltr" right title="" shadow >
            <v-divider></v-divider>
            <div class="px-3 py-2" style="direction:rtl">
                <v-row>
                    <v-col cols="12" md="12" sm="12">
                        <v-dialog
                            ref="dialog"
                            v-model="modal"
                            :return-value.sync="date"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="date"
                                label="تاريخ الاستلام - من"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="date"
                            scrollable
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="modal = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.dialog.save(date)"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-col>
                    <v-col cols="12" md="12" sm="12">
                        <v-dialog
                            ref="dialog1"
                            v-model="modal1"
                            :return-value.sync="date1"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="date1"
                                label="تاريخ الاستلام - إلى"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="date1"
                            scrollable
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="modal1 = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.dialog.save(date1)"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-col>
                    <v-col cols="12" md="12" sm="12">
                        
                    </v-col>
                </v-row>
            </div>
            <template #footer="" class="shadow">
                <div class="d-flex text-light align-items-center px-3 py-2">
                <b-button type="button" @click="getInvoices()" variant="success" class="ma-2" style="width:100px;">بحث</b-button>
                </div>
            </template>
        </b-sidebar>
    </div>
</template>

<script>
// import axios from 'axios'
import BreadCrumbs from '@/components/breadCrumbs.vue';
// import TabsComp from '@/components/tabsComp.vue';
export default{
    components:{BreadCrumbs},
    data() {
        return {
            headers: [
                {text: 'رقم الفاتورة' , value:'invoice_number'},
                {text: 'العميل' , value:'full_name'},
                {text: 'تاريخ الفاتورة' , value:'inv_date'},
                {text: 'الاجمالي' , value:'total'},
                {text: 'الضريبة' , value:'vat'},
                {text: 'المجموع' , value:'ftotal'},
            ],
            cardrows:[
                {
                    cardid: 1001, 
                    customer: '050000000 - عميل رقم واحد',
                    in_date: '2021-07-13',
                    total: 5000,
                    vat: 750,
                    ftotal: 5750,
                },
                {
                    cardid: 1002, 
                    customer: '050001000 - عميل رقم 2',
                    in_date: '2021-07-10',
                    total: 500,
                    vat: 75,
                    ftotal: 575,
                },
                {
                    cardid: 1003, 
                    customer: 'عميل رقم 3',
                    in_date: '2021-07-10',
                    total: 100,
                    vat: 15,
                    ftotal: 115,
                },
                {
                    cardid: 1004, 
                    customer: 'عميل رقم 4',
                    in_date: '2021-07-10',
                    total: 999,
                    vat: 149.85,
                    ftotal: 1148.85,
                },
            ],
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            date1: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            modal: false,
            modal1: false,
            active_tab:2,
            sdate:'',
            edate: '',
            items:[
                // {
                //     text: this.$store.state.hometitle,
                //     disabled: false,
                //     to: '/home',
                // },
                // {
                //     text: 'التقارير',
                //     disabled: true,
                //     to: '/reports',
                // },
                {
                    text: 'تقرير المبيعات',
                    disabled: true,
                    to: '/reports/sales-reports',
                },
                
            ],
            tabs: [
                {
                    index:3,name:'تقرير الضريبة',href:'/reports/vat-reports',class:'mytab4'
                },
                {
                    index:0,name:'تقرير اليومية',href:'/reports/daily-reports',class:'mytab1'
                },
                {
                    index:1,name:'تقرير البطاقات',href:'/reports/cards-reports',class:'mytab2'
                },
                {
                    index:2,name:'تقرير المبيعات',href:'/reports/sales-reports',class:'mytab'
                },
                {
                    index:4,name:'تقرير النسب',href:'/reports/percent-reports',class:'mytab5'
                }
            ]
        }
    },
    created() {
       // this.getInvoices();
    },
    methods: {
        getInvoices(){
            // const post = new FormData();
            // post.append("type" , 'allInvoices');
            // post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            // post.append("data[sdate]",this.sdate);
            // post.append("data[edate]",this.edate);
            // axios.post(
            //     this.$store.state.SAMCOTEC.r_path , post
            // ).then((response) => {
            //     const res = response.data.results.data;
            //     this.cardrows = res;
            // })
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
    },
}
</script>